import * as types from '../types'
import backend from '../../utils/backend'

/**
 * Searches the transaction details for the provided reference in the backend API.
 *
 * @param {String} ref The reference of the transaction to search for.
 *
 * @returns {Object|null} The transaction details, or null of no transaction was found.
 */
async function getTransaction(ref) {
  return await backend(`order/${ref}`)
}

/**
 * Performs the search of a transaction for the provided reference.
 */
export function searchReference(ref = undefined) {
  return async (dispatch, getState) => {
    try {
      const reference = ref || getState().search.reference
      await dispatch({ type: types.SEARCH_TRANSACTION_REQUEST, reference })

      const tx = await getTransaction(reference)

      if ('success' in tx.data && !tx.data.success) {
        await dispatch({
          type: types.SEARCH_TRANSACTION_FAILURE,
          message: 'TRANSACTION.TRANSACTION_NOT_FOUND',
        })
      } else {
        await dispatch({ type: types.SEARCH_TRANSACTION_SUCCESS, tx })
      }
    } catch (err) {
      dispatch({
        type: types.SEARCH_TRANSACTION_FAILURE,
        message:
          err.response.status === 404
            ? 'TRANSACTION.TRANSACTION_NOT_FOUND'
            : 'TRANSACTION.TRANSACTION_SEARCH_ERROR',
      })
    }
  }
}

/**
 * Updates the current search reference value.
 */
export function setSearchReference(reference) {
  return async dispatch =>
    dispatch({ type: types.SET_SEARCH_REFERENCE, reference })
}
