import React from 'react'
import { connect } from 'react-redux'

import { setSearchReference, searchReference } from '../../../state/actions/search'

import SearchInputView from './SearchInput-view'

class SearchInput extends React.Component {
  componentDidMount() {
    const parts = this.props.location.pathname.split('/')
    if (parts[2] && parts[2] !== '') {
      this.props.searchReference(parts[2])
    }
  }

  render() {
    return <SearchInputView {...this.props} />
  }
}

const mapStateToProps = (state) => ({
  reference: state.search.reference,
})

const mapDispatchToProps = dispatch => ({
  setSearchReference: (ref) => dispatch(setSearchReference(ref)),
  searchReference: (ref) => dispatch(searchReference(ref)),
})

const ConnectedSearchInput = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchInput)

export default ConnectedSearchInput

