import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../../utils/colors'
import LogoAndKey from '../../Home/CoinInfo/LogoAndKey'
import Arrow from './arrow.svg'

const Wrapper = styled.div`
  margin-bottom: 2rem;
  border-top: 1px solid ${Colors.GREY};
  padding-top: 2rem;
  padding-bottom: 4rem;
`

const Header = styled.div`
  color: ${Colors.GREY};
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 2rem;
`

const Content = styled.div`
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ArrowIcon = styled.img`
  margin: 0;

  @media (max-width: 992px) {
    margin-bottom: 1rem;
    transform: rotate(90deg);
  }
`

const DetailWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 3rem;

  &:first-of-type {
    padding-left: 0;
  }

  @media (max-width: 992px) {
    padding-left: 0;
    margin-bottom: 1rem;
  }
`

const DetailLabel = styled.span`
  color: ${Colors.GREY};
  font-size: 20px;
  line-height: 28px;
`

const DetailValue = styled.span`
  color: ${Colors.BLACK};
  font-size: 20px;
  line-height: 28px;
`

const Detail = ({ label, value }) => (
  <DetailWrapper>
    <DetailLabel>
      <FormattedMessage id={label} />
    </DetailLabel>
    <DetailValue>{value}</DetailValue>
  </DetailWrapper>
)

export default class Details extends React.Component {
  render() {
    const { transaction } = this.props
    return (
      <Wrapper>
        <Header>
          <FormattedMessage id="TRANSACTION.DETAILS" />
        </Header>
        <Content>
          <Detail
            label="TRANSACTION.ORIGIN_COIN"
            value={<LogoAndKey coin={transaction.depositCoin.toUpperCase()} />}
          />
          <Detail
            label="TRANSACTION.ORIGIN_AMOUNT"
            value={`${transaction.expectedDepositCoinAmount} ${
              transaction.depositCoin
            }`}
          />
          <ArrowIcon src={Arrow} alt="Arrow" />
          <Detail
            label="TRANSACTION.TARGET_COIN"
            value={
              <LogoAndKey coin={transaction.destinationCoin.toUpperCase()} />
            }
          />
          <Detail
            label="TRANSACTION.TARGET_AMOUNT"
            value={`${transaction.expectedDestinationCoinAmount} ${
              transaction.destinationCoin
            }`}
          />
        </Content>
      </Wrapper>
    )
  }
}
