import React from 'react'

// Presentational components
import SearchResults from '../components/Search/SearchResults'
import SearchInput from '../components/Search/SearchInput'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

import Background from '../components/Backgrounds/DefaultWithLogo'

export default class SearchPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="GoStable - Stabilize your digital assets within seconds" keywords={[`btc`, `bitcoin`, `stablecoin`, `stable`, `convert`, `trade`]} />
        <Navbar showLogoDesktop={true} />
        <SearchInput location={this.props.location} />
        <SearchResults />
        <Background useImage={false}>
          <Footer />
        </Background>
      </Layout>
    )
  }
}

