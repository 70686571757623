import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import hex2rgba from 'hex-rgba'
import Breakpoint from 'react-socks'

import * as Colors from '../../../utils/colors'
import Input from '../Input'
import Container from '../../Container'
import SimpleButton from '../../Buttons/SimpleButton'
import SearchIcon from './search.svg'

const Background = styled.div`
  background: ${Colors.BACKGROUND};
`

const StyledContainer = styled(Container)`
  padding: 10rem 1rem;

  @media (max-width: 992px) {
    padding: 4rem 1rem;
  }
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`

const Label = styled.span`
  color: ${Colors.BLACK};
  font-size: 28px;
  line-height: 56px;

  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const SearchButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-bottom: 0.25rem;
`

const Icon = styled.img`
  margin: 0;
`

const SearchInput = styled(Input)`
  flex: 1;
  border-bottom: 4px solid ${Colors.GREEN};
  color: black;
  text-align: left;
  font-size: 40px;
  line-height: 56px;
  margin-top: 2rem;

  &::placeholder {
    color: ${hex2rgba(Colors.BLACK, 30)};
  }

  @media (max-width: 992px) {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 2rem;
  }
`

export default class SearchInputView extends React.Component {
  submitForm(ev) {
    ev.preventDefault()
    this.props.searchReference()
  }

  render() {
    const { reference, setSearchReference } = this.props
    return (
      <Background>
        <StyledContainer>
          <FormWrapper onSubmit={ev => this.submitForm(ev)}>
            <Label>
              <FormattedMessage id="TRANSACTION.SEARCH_BY_REFERENCE_CODE" />
            </Label>
            <SearchInput
              placeholder="11111111-6c9e-4c53-9a6d-55e089aebd03"
              value={reference}
              onChange={ev => setSearchReference(ev.target.value)}
            />
            <Breakpoint large up>
              <SearchButton>
                <Icon
                  src={SearchIcon}
                  alt={<FormattedMessage id="TRANSACTION.SEARCH" />}
                />
              </SearchButton>
            </Breakpoint>

            <Breakpoint medium down>
              <SimpleButton>
                <FormattedMessage id="TRANSACTION.SEARCH" />
              </SimpleButton>
            </Breakpoint>
          </FormWrapper>
        </StyledContainer>
      </Background>
    )
  }
}
