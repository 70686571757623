import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import * as Colors from '../../../utils/colors'
import Loader from '../../Loader'
import Container from '../../Container'
import Background from '../../Backgrounds/DefaultWithLogo'

import Details from '../Details'

const Wrapper = styled(Container)`
  padding: 5rem 1rem;
`

const ErrorMessage = styled.span`
  color: ${Colors.BLACK};
  font-size: 40px;
  line-height: 56px;
`

const Context = styled.div`
  color: ${Colors.GREY};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 2rem;
`

const CurrentStatus = styled.div`
  color: ${Colors.BLACK};
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
`

const CurrentStatusDate = styled.div`
  color: ${Colors.GREY};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 4rem;
`

const statusEnum = status => {
  const possibleStatus = {
    no_deposit: 'No deposit detected 0%',
    confirming: 'Confirming deposit 25%',
    exchanging: 'Exchanging funds 50%',
    sending: 'Sending funds to your address 75% ',
    complete: 'Completed 100%',
    failed: 'Failed to convert funds',
    refunded: 'Refunded deposit',
    timeout: 'The deposit funds where not detected in useful time',
  }

  return possibleStatus[status]
}

export default class SearchResultsView extends React.Component {
  render() {
    const { message, loading, result } = this.props.search

    if (loading) {
      return (
        <Wrapper>
          <Loader size="M" />
        </Wrapper>
      )
    }

    if (!result) {
      return (
        message && (
          <Wrapper>
            <ErrorMessage>
              <FormattedMessage id={message} />
            </ErrorMessage>
          </Wrapper>
        )
      )
    }

    const transaction = result.data

    return (
      <Background color="white" logoColor="default" useImage={true} xPos="100%">
        <Wrapper>
          <Context>
            <b>{transaction.orderId}</b>
          </Context>

          <CurrentStatus>{statusEnum(transaction.status)}</CurrentStatus>

          <CurrentStatusDate>{`${moment
            .unix(transaction.createdAt / 1000)
            .format('YYYY-MM-DD HH:mm')}`}</CurrentStatusDate>

          <Details transaction={transaction} />
        </Wrapper>
      </Background>
    )
  }
}
