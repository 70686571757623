import React from 'react'
import { connect } from 'react-redux'

import SearchResultsView from './SearchResults-view'

class SearchResults extends React.Component {
  render() {
    return <SearchResultsView {...this.props} />
  }
}

const mapStateToProps = state => ({
  search: state.search,
})

const mapDispatchToProps = () => ({})

const ConnectedSearchResults = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults)

export default ConnectedSearchResults
